import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import DownloadIcon from '@material-ui/icons/PictureAsPdf';
import PropTypes from 'prop-types';
import React from 'react';
import { saveFilesFad } from '../../Utils/FirebaseMethods';
import { obtenerArchivos } from '../../Utils/Service';
import SnackBarAlert from '../../components/Generico/SnackBarAlert';
import { showLoading } from '../../hooks/showLoading';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'No. Solicitud',
  },
  {
    id: 'nombre',
    numeric: false,
    disablePadding: false,
    label: 'Nombre Asesor',
  },
  {
    id: 'correo',
    numeric: false,
    disablePadding: false,
    label: 'Correo Asesor',
  },
  {
    id: 'estatus',
    numeric: false,
    disablePadding: false,
    label: 'Estatus',
  },
  {
    id: 'tipoDocumento',
    numeric: false,
    disablePadding: false,
    label: 'Tipo de Documento',
  },
  { id: 'uid', numeric: true, disablePadding: false, label: 'UID' },
  { id: 'documento', numeric: false, disablePadding: false, label: 'Documento' },
];

function TablaBusqueda(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

TablaBusqueda.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('tipoDocumento');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows] = React.useState(props.data);
  const [snack, setSnack] = React.useState({
    msjSnackbar: '',
    severitySnackBar: 'warning',
    openSnackBar: false,
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const closeSnack = () => {
    setSnack({
      msjSnackbar: 'No se obtuvieron resultados.',
      severitySnackBar: 'warning',
      openSnackBar: false,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const descarga = (FAD, noSolicitud) => {
    Object.entries(FAD).forEach(([key, value]) => {
      const link = document.createElement('a');
      link.setAttribute('id', key);
      link.href = value;
      link.target = '_blank';
      link.download = noSolicitud + '.' + key;
      document.body.appendChild(link);
      if (key !== 'pdf') {
        link.click();
      } else {
        urlDownload(link, noSolicitud + '.' + key);
      }
      document.body.removeChild(link);
    });
    showLoading(false);
  };

  function urlDownload(url, filename) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', filename);
        a.click();
      });
    });
  }

  const handleSelected = async (solicitud) => {
    try {
      showLoading(true);
      if (solicitud.archivos.FAD.pdf == null) {
        const files = await obtenerArchivos(solicitud.firma_data.requisitionId);
        if (files != null) {
          if (!files.data.error) {
            await saveFilesFad(
              files,
              solicitud.no_solicitud,
              solicitud.agente_data.uid
            ).then((promesa) => {
              showLoading(false);
            });
          } else {
            showLoading(false);
            setSnack({
              msjSnackbar:
                files.data.error || 'No se pudieron obtener los archivos',
              severitySnackBar: 'warning',
              openSnackBar: true,
            });
          }
        } else {
          showLoading(false);
          setSnack({
            msjSnackbar: 'No se pudieron obtener los archivos',
            severitySnackBar: 'warning',
            openSnackBar: true,
          });
        }
      } else if (
        solicitud.archivos !== undefined &&
        solicitud.archivos.hasOwnProperty('FAD')
      ) {
        descarga(solicitud.archivos.FAD, solicitud.no_solicitud);
      }
    } catch (error) {
      showLoading(false);
    }
  };

  return (
    <React.Fragment>
      <SnackBarAlert
        severity={snack.severitySnackBar}
        isOpen={snack.openSnackBar}
        message={snack.msjSnackbar}
        closeSnack={closeSnack}
      />

      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer
            style={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: '#B0BEC5',
            }}
          >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <TablaBusqueda
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.no_solicitud)
                        }
                        tabIndex={-1}
                        key={index}
                        className={row.agente_data.uid}
                        style={{ height: 50 }}
                      >
                        <TableCell component="th" id={labelId} scope="row">
                          {row.no_solicitud}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.agente_data.nombre}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.firma_data?.firmantes?.asesor?.correo}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row?.estatus_solicitud}
                        </TableCell>
                        <TableCell align="left">
                          {row.solicitud_data.documento}
                        </TableCell>
                        <TableCell align="left">
                          {row.agente_data.uid}
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            color="primary"
                            onClick={() => {
                              handleSelected(row);
                            }}
                          >
                            <DownloadIcon className={classes.iconPdf} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={'Filas por página'}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count}`
            }
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    </React.Fragment>
  );
}
