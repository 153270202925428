import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";
import Firebase from "../Utils/Firebase";
import { getCollections } from "../Utils/Service";
import SnackBarAlert from "../components/Generico/SnackBarAlert";
import TablaBusqueda from "../components/Generico/TablaBusqueda";
import { showLoading } from "../hooks/showLoading";

const useStyles = makeStyles((theme) => ({
  card: {
    marginLeft: "2%",
    marginRight: "2%",
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const VisorDocumentos = () => {
  const classes = useStyles();
  const [criterio, setCriterio] = React.useState("");
  const [value, setValue] = React.useState("");
  const [snack, setSnack] = React.useState({
    msjSnackbar: "No se obtuvieron resultados.",
    severitySnackBar: "warning",
    openSnackBar: false,
  });
  const [input, setInput] = React.useState({
    label: "Número de solicitud",
  });

  const [searchData, setData] = React.useState([]);

  const handleChange = (event) => {
    switch (event.target.value) {
      case "NS":
        setInput({ label: "Número de solicitud" });
        break;
      case "AP":
        setInput({ label: "Nombre del Asesor Comercial" });
        break;
      default:
        setInput({ label: "Enlace que inició el proceso de solicitud" });
        break;
    }
    setValue("");
    setCriterio(event.target.value);
  };
  const handleInputChange = (event) => {
    setValue(event.target.value);
  };

  const closeSnack = () => {
    setSnack({
      msjSnackbar: "No se obtuvieron resultados.",
      severitySnackBar: "warning",
      openSnackBar: false,
    });
  };

  const handleClick = async (event) => {
    showLoading(true);
    setData([]);
    const data = [];
    const lista = await getCollections();
    const uids = lista !== undefined ? lista.data : [];
    for (let index = 0; index < uids.length; index++) {
      const element = uids[index];
      if (criterio === "NS") {
        serchForNS(element, data, index, uids);
      } else {
        serchAnother(element, data, index, uids);
      }
    }
  };

  const serchForNS = (element, data, index, uids) => {
    const query = Firebase.searchData()
      .collection(element)
      .where("no_solicitud", "==", value);

    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        data.push(doc.data());
      });

      if (index === uids.length - 1) {
        setData(data);
        showLoading(false);
        if (data.length === 0) {
          setSnack({ ...snack, openSnackBar: true });
        }
      }
    });
  };

  const serchAnother = (element, data, index, uids) => {
    const query = Firebase.searchData()
      .collection(element)
      .orderBy("date", "desc");

    query.get().then(function (querySnapshot) {
      querySnapshot.forEach(function (doc) {
        if (
          criterio === "EA" &&
          doc.data().agente_data.nombre.includes(value)
        ) {
          data.push(doc.data());
        } else if (
          criterio === "AP" &&
          doc.data().firma_data.firmantes != null &&
          doc.data().firma_data.firmantes.asesor != null &&
          doc.data().firma_data.firmantes.asesor.nombre.includes(value)
        ) {
          data.push(doc.data());
        }
      });

      if (index === uids.length - 1) {
        setData(data);
        showLoading(false);
        if (data.length === 0) {
          setSnack({ ...snack, openSnackBar: true });
        }
      }
    });
  }

  return (
    <Card className={`${classes.card} container-pages`}>
      <Grid container direction="row" alignItems="center" spacing={4}>
        <Grid item xs={12} sm={3}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="criterios-busqueda">
              Criterios de búsqueda
            </InputLabel>
            <Select
              labelId="criterios-busqueda"
              id="busqueda-select"
              onChange={handleChange}
              value={criterio}
            >
              <MenuItem value={"NS"}>Número de solicitud</MenuItem>
              <MenuItem value={"AP"}>Asesor patrimonial</MenuItem>
              <MenuItem value={"EA"}>Enlace administrativo</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label={input.label}
            required
            name="inputSearch"
            onChange={handleInputChange}
            value={value}
            disabled={criterio === ""}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClick}
            endIcon={<SearchIcon>Buscar</SearchIcon>}
            disabled={value === ""}
          >
            Buscar
          </Button>
        </Grid>
      </Grid>

      {searchData.length > 0 && <TablaBusqueda data={searchData} />}
      <SnackBarAlert
        severity={snack.severitySnackBar}
        isOpen={snack.openSnackBar}
        message={snack.msjSnackbar}
        closeSnack={closeSnack}
      />
    </Card>
  );
};
